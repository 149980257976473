import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./fonts/mm/Pyidaungsu-1.8.3_Regular.ttf";
import "./fonts/mm/Pyidaungsu-1.8.3_Bold.ttf";

import "./styles/index.scss";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("moodzhub-landing-root")
);
