import * as React from "react";

import MoodStickers from "./components/MoodStickers/MoodStickers";
import NavigationBar from "./components/NavigationBar/NavigationBar";

import Logo from "./images/logos/Logo.png";
// import iosPlaceholder from "./images/app-screenshots/iOS/placeholder-ios.png";
import iosActivitiesScreen from "./images/app-screenshots/iOS/activities(ios).jpg";
import iosMainScreen from "./images/app-screenshots/iOS/main(ios).jpg";
import iosProfileScreen from "./images/app-screenshots/iOS/profile(ios).jpg";
import androidHistoryScreen from "./images/app-screenshots/android/history(android).jpg";
import androidMainScreen from "./images/app-screenshots/android/main(android).jpg";
import androidProfileScreen from "./images/app-screenshots/android/profile(android).jpg";
import webHistoryScreen from "./images/app-screenshots/web/history(web).png";
import webLoginScreen from "./images/app-screenshots/web/login(web).png";
import webMainScreen from "./images/app-screenshots/web/main(web).png";
import appStoreLogo from "./images/logos/appstore.png";
import playstoreLogo from "./images/logos/playstore.png";
import kukuEnglish from "./images/logos/about-kuku-en.png";
import kukuMyanmar from "./images/logos/about-kuku-mm.png";
import fbIcon from "./images/logos/facebook-icon.svg";
// import instagramIcon from "./images/logos/instagram-icon.svg";
import viberIcon from "./images/logos/viber-icon.svg";
import telegramIcon from "./images/logos/telegram-icon.svg";
import linkedinIcon from "./images/logos/linkedin-icon.svg";
import discordIcon from "./images/logos/discord-icon.svg";
import plusIcon from "./images/icons/plus.svg";
import minusIcon from "./images/icons/minus.svg";

import faqData from "./data/faq/faq";

import "./App.scss";

function Home() {
  const [faqLanguage, setFaqLanguage] = React.useState("EN"); // EN | MM
  return (
    <div className="MoodzhubLanding">
      {/* Nav Bar */}
      <NavigationBar />

      <div className="MainHeaderContainer MoodzhubTop" id="home">
        <div className="MainLogo">
          <img src={Logo} alt="app-logo" className="LogoImage" />
        </div>
        <div className="MaxWidthContainer">
          <MoodStickers />
        </div>
      </div>

      <div className="MaxWidthContainer MoodzhubAnchor" id="about-us">
        <div className="AppDetails">
          <div className="AppBrandStory">
            <div style={{ marginBottom: 40 }}>
              <h4 className="EN EN-header PrimaryText">
                You can see a physical trauma with your eyes but you cannot see
                what’s happening in your mind.
              </h4>

              <p className="EN EN-text PrimaryText">
                MoodzHub mood tracker and journaling app acts as a core center
                where your moods of joy, sadness, anger, fear are gathered in
                one. MoodzHub helps you see what’s happening in your mind and
                let you know about what is happening to you.
              </p>
            </div>

            <div>
              <h4 className="MM MM-header PrimaryText">
                ရုပ်ပိုင်းဆိုင်ရာ ဒဏ်ရာတွေကို မျက်လုံးနဲ့ မြင်တွေ့နိုင်ပေမဲ့
                စိတ်ထဲက ဒဏ်ရာတွေကို မမြင်နိုင်ပါဘူး။
              </h4>

              <p className="MM MM-text PrimaryText">
                <span className="EN EN-text">MoodzHub mood tracker</span> နဲ့{" "}
                <span className="EN EN-text">journaling app</span> လေးက သင့်ရဲ့
                ပျော်ရွှင်၊ ၀မ်းနည်း၊ ဒေါသထွက်၊ ကြောက်နေတဲ့{" "}
                <span className="EN EN-text">moods</span> တွေကို မြင်သာအောင်
                တစ်နေရာတည်းမှာ စုစည်းပေးတဲ့ နေရာတစ်ခုပါ။
                ကိုယ့်စိတ်ထဲကဖြစ်ပျက်နေတာတွေကို မြင်တွေ့စေနိုင်ပြီး
                အကြောင်းအရင်းတွေကို&nbsp;ရှာဖွေသိရှိနိုင်မှာပါ။
              </p>
            </div>
          </div>

          <div className="AppMascotStory">
            <div>
              <img src={kukuEnglish} alt="" className="AppMascotImage" />
              <p className="EN EN-text PrimaryText">
                Meet KuKu (Ku Mu Dra - Lotus Queen) and friend BeBe, your
                friendly mood helpers to let you see your moods. KuKu will help
                healthy individuals stay in healthy emotional states and assist
                individuals with mental diseases, such as bipolar disorder and
                depression, in their health management as a tool. Users can
                reflect their emotions and self-regulate their emotional
                well-being.
              </p>
            </div>

            <div>
              <img src={kukuMyanmar} alt="" className="AppMascotImage" />
              <p className="MM MM-text PrimaryText">
                <span className="EN EN-text">KuKu</span>
                &nbsp;&nbsp;နဲ့မိတ်ဆက်ပေးပါရစေ။ ကုမုဒြာ&nbsp;&nbsp;
                <span className="EN EN-text">-</span>
                &nbsp;&nbsp;ကြာပန်းဘုရင်မလေး{" "}
                <span className="EN EN-text">KuKu</span>
                နဲ့သူငယ်ချင်း <span className="EN EN-text">BeBe</span>
                &nbsp;&nbsp;တို့က သင်တို့ရဲ့စိတ်တွေကိုမြင်အောင်ကူညီပေးမှာပါ။
                စိတ်ပိုင်းဆိုင်ရာကျန်းမာနေအောင်ကူညီပေးပြီး&nbsp;&nbsp;
                <span className="EN EN-text">bipolar</span>&nbsp;&nbsp;နဲ့
                စိတ်ဓာတ်ကျခြင်းစတဲ့&nbsp;&nbsp;စိတ်ပိုင်းဆိုင်ရာရောဂါများ&nbsp;&nbsp;ကုသရာမှာလည်း
                အသုံး၀င်စေမဲ့ <span className="EN EN-text">tool</span>{" "}
                တစ်ခုအဖြစ်ရှိပေးနေမှာပါ။ ကိုယ့်ရဲ့
                စိတ်အခြေအနေတွေကို&nbsp;&nbsp;သုံးသပ်ပြီး&nbsp;&nbsp;စိတ်ကျန်းမာရေးအတွက်
                ကိုယ်တိုင်လည်း&nbsp;&nbsp;ထိန်းညှိနိုင်မှာပါ။
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* App Screenshots Section */}
      <div className="MaxWidthContainer MoodzhubAnchor" id="app-features">
        <div className="AppSection">
          <div className="App">
            <div className="AppScreens">
              <img
                className="Screen"
                src={androidHistoryScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={androidMainScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={androidProfileScreen}
                alt=""
                aria-disabled
              />
            </div>

            <a
              className="AppLink"
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.moodzhub.moodzhub"
              rel="noreferrer noopener"
            >
              <img src={playstoreLogo} alt="playstore" />
            </a>
          </div>

          <div className="App">
            <div className="AppScreens">
              <img
                className="Screen"
                src={iosActivitiesScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={iosMainScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={iosProfileScreen}
                alt=""
                aria-disabled
              />
            </div>

            <a
              className="AppLink"
              target="_blank"
              href="https://apps.apple.com/us/app/moodz-hub/id1599241132"
              rel="noreferrer noopener"
            >
              <img src={appStoreLogo} alt="appstore" />
            </a>
          </div>
        </div>

        <div className="WebApp">
          <div className="App">
            <div className="AppScreens">
              <img
                className="Screen"
                src={webMainScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={webLoginScreen}
                alt=""
                aria-disabled
              />
              <img
                className="Screen"
                src={webHistoryScreen}
                alt=""
                aria-disabled
              />
            </div>

            <a
              className="AppStateSection"
              target="_blank"
              href="https://moodzhub.com"
              rel="noreferrer noopener"
            >
              Try it here <br />
              on Website
            </a>
          </div>
        </div>
      </div>

      <div className="MoodzhubFAQ" id="faq">
        <div className="MaxWidthContainer">
          <h4 className="EN EN-header PrimaryText">
            Frequently Asked Questions
          </h4>
          <div>
            <div className="LanguageSwitch">
              <div
                className={`EN EN-header Language  ${
                  faqLanguage === "EN" ? "Active" : ""
                }`}
                onClick={() => setFaqLanguage("EN")}
              >
                English
              </div>
              <div className="EN EN-header PrimaryText" style={{ margin: 0 }}>
                {"--"}
              </div>
              <div
                className={`MM MM-header Language  ${
                  faqLanguage === "MM" ? "Active" : ""
                }`}
                onClick={() => setFaqLanguage("MM")}
              >
                မြန်မာ
              </div>
            </div>
          </div>

          <div>
            {faqData[faqLanguage].map((each, index) => (
              <FAQItem
                key={index}
                title={each.title}
                body={each.body}
                language={faqLanguage}
              />
            ))}
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="MoodzhubFooter " id="contact-us">
        <div className="MaxWidthContainer">
          <div className="MoodzhubFooterBlock">
            <div>
              <div className="ContactUsBlock">
                <span className="PrimaryText">Contact Us&nbsp;:&nbsp;</span>
                <a className="PrimaryText" href="mailto:hello@moodzhub.com">
                  hello@moodzhub.com
                </a>
              </div>
              <div className="ContactUsBlock">
                <span className="PrimaryText">Social Media&nbsp;:&nbsp;</span>
                <a
                  href="https://www.facebook.com/MoodzHubMM"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                >
                  <img className="SocialMediaIcon" src={fbIcon} alt="" />
                </a>
                {/* hide instagram link for now */}
                {/* <a
                  href="/#"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                >
                  <img className="SocialMediaIcon" src={instagramIcon} alt="" />
                </a> */}
                <a
                  href="https://invite.viber.com/?g2=AQAtdAiHW8FPcE5BXhAyC88HH53cdeU48VkgGsKEJL%2Blikw7Smc10JKFD%2B0GbFWw"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Viber"
                >
                  <img className="SocialMediaIcon" src={viberIcon} alt="" />
                </a>
                <a
                  href="https://t.me/moodzhub"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Telegram"
                >
                  <img className="SocialMediaIcon" src={telegramIcon} alt="" />
                </a>
                <a
                  href="https://www.linkedin.com/company/moodz-hub/"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Linkedin"
                >
                  <img className="SocialMediaIcon" src={linkedinIcon} alt="" />
                </a>
                <a
                  href="https://discord.gg/eUEw27yQ35"
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Discord"
                >
                  <img className="SocialMediaIcon" src={discordIcon} alt="" />
                </a>
              </div>
            </div>

            <div className="CopyRightBlock PrimaryText">
              Copyright © 2021. All Rights Reserved
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

const FAQItem = ({ title = "", language = "EN", body = <></> }) => {
  const [showFaqBody, setShowFaqBody] = React.useState(false);

  return (
    <div className="FaqItem">
      <div>
        <img
          className="FaqItem__icon"
          onClick={() => setShowFaqBody(!showFaqBody)}
          src={showFaqBody ? minusIcon : plusIcon}
          alt=""
        />
      </div>
      <div>
        <div
          className={`FaqItem__title ${
            language === "EN" ? "EN EN-header" : "MM MM-header"
          }`}
          onClick={() => setShowFaqBody(!showFaqBody)}
        >
          {title}
        </div>
        <div
          className={`${
            language === "EN" ? "EN EN-text" : "MM MM-text"
          } FaqItem__body ${showFaqBody ? "" : "FaqItem__body-collapsed"}`}
        >
          {body}
        </div>
      </div>
    </div>
  );
};

export default Home;