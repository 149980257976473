import * as React from "react";
import Logo from "./images/logos/Logo.png";

const Terms = () => {
    return (
        <div className="MaxContainer">
            <div className="MoodzhubTop" id="home">
                <div className="MainLogo">
                    <img src={Logo} alt="app-logo" className="LogoImage" />
                </div>
            </div>
            <div className="MaxWidthContainer">
                <div style={{ margin: 20 }}>
                    <h2>Terms of Service</h2>
                    <p>
                        To use the Moodzhub.com website owned and operated by MOODZHUB you, the
                        User, must agree to the Terms of Service agreement outlined below.
                    </p>

                    <h3>1. Acceptance of Terms</h3>
                    <p>
                        By choosing to create an account on the Moodzhub.com website, user
                        represents and warrants that he or she is 13 years old or older and agrees
                        to the terms and conditions set forth in this agreement.
                    </p>

                    <h3>This Site is for Informational Purposes</h3>
                    <p>THE SITE DOES NOT PROVIDE MEDICAL ADVICE.</p>
                    <p>
                        The content on the Moodzhub.com website, such as text, graphics, images,
                        and other material contained on the Moodzhub.com website ("Content") are
                        for informational purposes only. The Content is not intended to be a
                        substitute for professional medical advice, diagnosis, or treatment.
                        Always seek the advice of your physician or other qualified health
                        provider with any questions you may have regarding a medical condition.
                        Never disregard professional medical advice or delay in seeking it because
                        of something you have read on the Moodzhub.com website!
                    </p>
                    <p>
                        If you think you may have a medical emergency, call your doctor or 911
                        immediately. MOODZHUB does not recommend or endorse any specific tests,
                        physicians, products, procedures, opinions, or other information that may
                        be mentioned on the Site. Reliance on any information provided by
                        MOODZHUB, its employees, others appearing on the website at the invitation
                        of MOODZHUB, or other visitors to the website is solely at your own risk.
                    </p>

                    <h3>User Privacy</h3>
                    <p>
                        User agrees that MOODZHUB shall have technical access to user's account in
                        order to respond to technical questions or to offer technical support.
                    </p>
                    <p>
                        If the User chooses to use the site's Wellness Team feature, User agrees
                        that his or her Moodzhub.com data may be viewed by other individuals. The
                        User has the ability to permit and revoke access to their data at their
                        discretion at any time. User also has the option to keep certain data
                        private.
                    </p>
                    <p>
                        The site allows the User to share data publicly on the site's forum pages.
                        User is able to edit forum posts that they have created. User acknowledges
                        and permits data shared on the forum to be publicly accessible on the
                        Internet.
                    </p>
                    <p>
                        Additional privacy guidelines are covered in our{" "}
                        <a href="https://moodzhub.com/privacy-policy.html" className="privacy_link">Privacy Policy</a>
                    </p>

                    <h3>User Contact</h3>
                    <p>
                        User agrees to allow MOODZHUB to contact the User by email. MOODZHUB's
                        email contact with User is limited to matters concerning the operation and
                        use of User's account and for matters of instruction on the use and
                        operation of the Moodzhub.com website. MOODZHUB will NOT contact Users
                        about commercial offers from itself or any other party.
                    </p>

                    <h3>5. Account Deactivation</h3>
                    <p>
                        Failure to abide by the terms of this agreement will result in immediate
                        account deactivation.
                    </p>

                    <h3>6. Indemnification</h3>
                    <p>
                        User agrees to indemnify and hold harmless MOODZHUB, its officers,
                        directors, employees and agents, from any claim or demand, including
                        reasonable attorneys fees, made by any third party due to or arising out
                        of user's conduct, user's use of the Service, any alleged violation of
                        this agreement, or any alleged violation of any rights of another,
                        including but not limited to user's use of any content, trademarks,
                        service marks, trade names, copyrighted or patented material, or other
                        intellectual property used in connection with user's account. MOODZHUB
                        reserves the right, at its own expense, to assume the exclusive defense
                        and control of any matter otherwise subject to indemnification by the
                        user, but doing so shall not excuse user's indemnity obligations.
                    </p>

                    <h3>7. Disclaimer of Liability</h3>
                    <p>
                        THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT
                        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING BUT NOT
                        LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE
                        OR NON-INFRINGEMENT. NEITHER THIS AGREEMENT OR ANY DOCUMENTATION FURNISHED
                        UNDER IT IS INTENDED TO EXPRESS OR IMPLY ANY WARRANTY THAT THE ONLINE SITE
                        SERVICES WILL BE UNINTERRUPTED, TIMELY OR ERROR-FREE OR THAT THE SERVICE
                        WILL PROVIDE UNINTERRUPTED, TIMELY OR ERROR-FREE SERVICE. THE SECURITY
                        MECHANISM INCORPORATED INTO THE WEBSITE HAS INHERENT LIMITATIONS AND USER
                        MUST DETERMINE THAT THE WEBSITE ADEQUATELY MEETS ITS REQUIREMENTS. USER
                        ACKNOWLEDGES AND AGREES THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR
                        OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICES IS DONE AT ITS OWN
                        DISCRETION AND RISK AND THAT USER WILL BE SOLELY RESPONSIBLE FOR ANY
                        DAMAGES TO ITS COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
                        DOWNLOAD OF SUCH MATERIAL AND/OR DATA. MOODZHUB, ITS OFFICERS, DIRECTORS,
                        EMPLOYEES AND AGENTS, SHALL NOT BE LIABLE, UNDER ANY CIRCUMSTANCES OR
                        LEGAL THEORIES WHATSOEVER, FOR ANY LOSS OF BUSINESS, PROFITS OR GOODWILL,
                        LOSS OF USE OR DATA, INTERRUPTION OF BUSINESS, OR FOR ANY INDIRECT,
                        SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY CHARACTER, EVEN IF
                        MOODZHUB IS AWARE OF THE RISK OF SUCH DAMAGES, THAT RESULT IN ANY WAY FROM
                        USERS USE OR INABILITY TO USE THE ONLINE SITE SERVICES, OR THAT RESULT
                        FROM ERRORS, DEFECTS, OMISSIONS, DELAYS IN OPERATION OR TRANSMISSION, OR
                        ANY OTHER FAILURE OF PERFORMANCE OF THE ONLINE SITE SERVICES. SOME
                        JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR
                        LIABILITIES, SO SOME OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                    </p>

                    <h3>8. Agreement Modification</h3>
                    <p>
                        Moodzhub reserves the right to modify this agreement at its sole
                        discretion at any time without prior written notice.
                    </p>

                    <h3>9. General</h3>
                    <p>
                        This agreement and the relationship between User and MOODZHUB shall be
                        governed by the laws of the state of Utah without regard to its conflict
                        of law provisions. User and MOODZHUB agree to submit to the personal and
                        exclusive jurisdiction of the courts of the state of Utah. MOODZHUB's
                        failure to exercise or enforce any right or provision of this agreement
                        shall not constitute a waiver of such right or provision. If any provision
                        of this agreement is found by a court of competent jurisdiction to be
                        invalid, the parties nevertheless agree that the court should endeavor to
                        give effect to the parties intentions as reflected in the provision, and
                        agree that the other provisions of this agreement remain in full force and
                        effect. User agrees that regardless of any statute or law to the contrary,
                        any claim or cause of action arising out of or related to use of the
                        Service or this agreement must be filed within one (1) year after such
                        claim or cause of action arose, or be forever barred. The section titles
                        in this agreement are for convenience only and have no legal or
                        contractual effect.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Terms;