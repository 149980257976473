const faqData = {
  EN: [
    {
      title: "What is MoodzHub app",
      body: (
        <div>
          MoodzHub app is a mood tracker, a tool to record and track your moods. It lets you aware more of your moods and understand better whether your mood is positive or negative. You can also write down as a diary. reflect yourself and find what the causes.
          <br />
          Disclaimer :{" "}
          <ul>
            <li>
              Moodz Hub app does not provide counselling treatment or interventions to address mental illnesses and suicidal ideation.
            </li>
            <li>
              MoodzHub app can be used as a tool by yourself or with the help of professional psychologists.
            </li>
            <li>
              If you feel depressed, lonely or suicidal, you can seek help from Call Me Today, Mee Pya Tike and other psychological services.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Can you add mood from the previous days?",
      body: <p>Yes. You can choose the time and date to add.</p>,
    },
    {
      title: "Can you edit/delete the moods added?",
      body: <p>Yes, you can.</p>,
    },
    {
      title: "Available on which platform? Android, iOS?",
      body: <p>MoodzHub has Andriod and iOS</p>,
    },
    {
      title: "Where are the data stored when you added into MoodzHub App?",
      body: (
        <div>
          At MoodzHub, we understand the importance of keeping your information safe. That's why we employ robust security measures to ensure your data's privacy:
          <br />
          <ul>
            <li>
              Secure Server Storage: Your data is stored on secure servers protected by industry-standard security measures.
            </li>
            <li>
              Encryption : We encrypt your data at rest on our servers and in transit when you access it from your devices. 
            </li>
            <li>
              Cross-Device Access with Syncing: You can conveniently access your MoodzHub data from any of your device, anytime. Because your data is synced across all platforms, you'll always have your latest information at your fingertips
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Safety of your personal data.",
      body: (
        <p>
          Your personal data (except Profile picture from Facebook login and email) are NOT saved by MoodzHub. Data is saved in your own personal account. Only you have access and control over it.
        </p>
      ),
    },
  ],
  MM: [
    {
      title: (
        <p>
          MoodzHub app ဆိုတာဘာ app လဲ?
        </p>
      ),
      body: (
        <div>
          MoodzHub app ဟာ mood tracker (ကိုယ့်စိတ်ခံစားချက်များကို မှတ်တမ်းမှတ်ယူနိုင်တဲ့) app တစ်ခုဖြစ်ပါတယ်။ MoodzHub ကို အသုံးပြုခြင်းဖြင့် သင့်ဖြစ်ပေါ်နေတဲ့ mood (ခံစားချက်များကို) ပိုမို သတိထား သိမြင်နိုင်ပြီး မကောင်းသော mood၊ အနှုတ်လက္ခဏာဆောင်သော moodများကို နားလည်စေပါတယ်။ ဖြစ်ပေါ်နေရတဲ့ စိတ်ခံစားချက်တွေရဲ့ အကြောင်းအရင်းတွေကိုလည်း app ထဲမှာမှတ်သားထားတဲ့ မှတ်စုကို ပြန်လည်ကြည့်ပြီး သုံးသပ်နိုင်ပါတယ်။
          <br />
          ရှင်းလင်းချက် :
          <ul>
            <li>
              MoodzHub app သည် လိုင်စင်ရ လေ့ကျင့်ထားသော ကျန်းမာရေးနှင့် စိတ်ပညာပညာရှင်နှင့် အစားမထိုးနိုင်ပါ။ စိတ်ရောဂါများအတွက် ကုသမှုပေးနိုင်ခြင်းလည်းမရှိပါ။
            </li>
            <li>
              MoodzHub app ကို tool တစ်ခုအနေဖြင့်လည်း စိတ်ပညာရှင်များ၏ အကူအညီ၊ ဆွေးနွေးတိုင်ပင်ချက်များအတိုင်း သုံးနိုင်ပါသည်။
            </li>
            <li>
              စိတ်ကျရောဂါအပါအ၀င် တခြားစိတ်ရောဂါများပြင်းပြင်းထန်ထန်ခံစားရပါကCall Me Today, Mee Pya Tike နဲ့ တခြား စိတ်ပိုင်းဆိုင်ရာဆွေးနွေးပေးသည့် ပညာရှင်များ၊ ၀န်ဆောင်မှုများသို့ ဆက်သွယ် အကူအညီရယူနိုင်ပါသည်။
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: (
        <p>
          လွန်ပြီးသားရက်တုန်းက အချိန်မှာဖြစ်ပျက်တဲ့ mood ကို ပြန်ထည့်လို့ရနိုင်လား?
        </p>
      ),
      body: <p>ရပါတယ်! အချိန်၊ နေ့ရက်တွေကို ပြန်ရွေးပြီး ထည့်နိုင်ပါတယ်။</p>,
    },
    {
      title: (
        <p>
          ထည့်ပြီးသား mood တွေကို ပြန်ပြင်၊ ပြန်ဖျက်တာလုပ်နိုင်လား?
        </p>
      ),
      body: <p>လုပ်နိုင်ပါတယ်</p>,
    },
    {
      title: (
        <p>
          Android နဲ့ iOS ဘယ်မှာရလဲ?
        </p>
      ),
      body: (
        <p>
          ဖုန်းအတွက် Android နှင့် iOS ၂ ခုလုံး မှာရရှိနိုင်ပါတယ်။
        </p>
      ),
    },
    {
      title: (
        <p>
          Moodz Hub App ထဲထည့်လိုက်တဲ့ data အချက်အလက် တွေကို ဘယ်မှာသိမ်းထားမှာလဲ?
        </p>
      ),
      body: (
        <div>
          MoodzHub ကို အသုံးပြုကြတဲ့ Moodzie တို့ရဲ့  ကိုယ်ရေးကိုယ်တာအချက်အလက်တွေကို လုံခြုံစွာထားရှိပါတယ်။"
          <br />
          <ul>
            <li>
              Secure Server Storage (လုံခြုံသော ဆာဗာတွင် သိမ်းထားခြင်း) : Moodzie တို့ရဲ့ ဒေတာများကို နည်းပညာနဲ့လုပ်ငန်းဆိုင်ရာအရည်သွေးပြည့်မီတဲ့ လုံခြုံရေးလုပ်ငန်းစဥ်အတိုင်းသိမ်းဆည်းထားပါတယ်
            </li>
            <li>
              Encryption : ဆာဗာထဲတွင် သိမ်းထားသည့်အချိန်နှင့် အကူးအပြောင်းအချိန်တွင် ဒေတာများကို encrypt လုပ်ထားပါတယ်။ တနည်းအားဖြင့် ဒေတာများကို လျှို့၀ှက်ကုဒ်၀ှက်အဖြစ်ပြောင်းပေးထားခြင်းကိုဆိုလိုပါတယ်။
            </li>
            <li>
              Cross-Device Access with Syncing : MoodzHub ရှိမှတ်ထားသည့် ဒေတာများကို သင့်ရဲ့ ဘယ် device  ဖုန်းမှာမဆို အချိန်မရွေးအဆင်ပြေစွာပြန်ကြည့်နိုင်ပါတယ်။ Platform တွေ တစ်ခုနဲ့တစ်ခု sync ချိတ်ဆက်လို့ရအောင်ဆောင်ရွက်ပေးထားတဲ့အတွက်ကြောင့်ပါ။
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: <p>ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ ဘယ်လောက်လုံခြုံပါလဲ?</p>,
      body: (
        <p>
          ပုဂ္ဂိုလ်ရေးဆိုင်ရာ အချက်အလက်များ (Facebook Login မှ ပုံ၊emailမှလွဲပြီး) Moodz Hub မှ လုံး၀ စုစည်းသိမ်းထားခြင်းမရှိပါဘူး။ Userများရဲ့ ကိုယ်ပိုင် account ထဲမှာသာ လုံခြုံစွာသိမ်းထားမှာဖြစ်ပြီး userများသာ ရပိုင်ခွင့် ထိန်းချုပ်ပိုင်ခွင့် အပြည့်ရှိပါတယ်။
        </p>
      ),
    },
  ],
};

export default faqData;
