import { Routes, Route, Link } from "react-router-dom";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Home from "./Home";

export default function App() {
    return (
        <Routes>
            <Route path="/" >
                <Route index element={<Home />} />
                <Route path="privacy-policy" element={<Privacy />} />
                <Route path="terms-of-service" element={<Terms />} />
                <Route path="*" element={<NoMatch />} />
            </Route>
        </Routes>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}