import Lottie from "react-lottie";
import { useMedia } from "react-use";

import AngryBarlarData from "../../data/stickers/Anger_Barlar.json";
import JoyHappyData from "../../data/stickers/Joy_Happy.json";
import OtherEmptyData from "../../data/stickers/Other_Empty.json";

import "./MoodStickers.scss";

const options = (lottieJson) => ({
  loop: true,
  autoplay: true,
  animationData: lottieJson,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
});

const StickerStyles = ({
  isMobile = false,
  isTablet = false,
  isDesktop = true,
  index = 0,
}) => {
  const MobileStickerStyles = [
    {
      marginRight: "-30%",
      transform: "translate(-100%, -50%) rotate(10deg)",
      height: "180px",
      width: "180px",
      position: "absolute",
      top: "40%",
      left: "48.5%",
    },
    {
      height: "200px",
      width: "230px",
      zIndex: 10,
    },
    {
      marginLeft: "-30%",
      transform: "translate(100%, -50%) rotate(-10deg)",
      height: "180px",
      width: "180px",
      position: "absolute",
      top: "40%",
      right: "48.5%",
    },
  ];

  const TabletStickerStyles = [
    {
      marginRight: "-32.5%",
      transform: "translate(-100%, -50%) rotate(10deg)",
      height: "300px",
      width: "300px",
      position: "absolute",
      top: "40%",
      left: "49.5%",
    },
    {
      height: "320px",
      width: "350px",
      zIndex: 10,
    },
    {
      marginLeft: "-32.5%",
      transform: "translate(100%, -50%) rotate(-10deg)",
      height: "300px",
      width: "300px",
      position: "absolute",
      top: "40%",
      right: "49.5%",
    },
  ];

  const DesktopStickerStyles = [
    {
      marginRight: "-32.5%",
      transform: "rotate(10deg)",
      height: "500px",
      width: "500px",
    },
    {
      height: "520px",
      width: "550px",
      zIndex: 10,
    },
    {
      marginLeft: "-32.5%",
      transform: "rotate(-10deg)",
      height: "500px",
      width: "500px",
    },
  ];

  if (isDesktop) return DesktopStickerStyles[index];
  if (isTablet) return TabletStickerStyles[index];
  if (isMobile) return MobileStickerStyles[index];
};

const MoodStickers = () => {
  const isMobile = useMedia("(min-width: 320px)");
  const isTablet = useMedia("(min-width: 768px)");
  const isDesktop = useMedia("(min-width: 1200px)");

  return (
    <div className="Stickers">
      <Lottie
        options={options(AngryBarlarData)}
        style={StickerStyles({ isMobile, isTablet, isDesktop, index: 0 })}
        isClickToPauseDisabled
      />
      <Lottie
        options={options(JoyHappyData)}
        style={StickerStyles({ isMobile, isTablet, isDesktop, index: 1 })}
        isClickToPauseDisabled
      />
      <Lottie
        options={options(OtherEmptyData)}
        style={StickerStyles({ isMobile, isTablet, isDesktop, index: 2 })}
        isClickToPauseDisabled
      />
    </div>
  );
};

export default MoodStickers;
