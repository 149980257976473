import * as React from "react";
import { useMedia } from "react-use";

import "./NavigationBar.scss";

const NavMenus = [
  {
    anchorLink: "#home",
    title: "Home",
  },
  {
    anchorLink: "#about-us",
    title: "About Us",
  },
  {
    anchorLink: "#app-features",
    title: "App Features",
  },
  {
    anchorLink: "#faq",
    title: "FAQ",
  },
  {
    anchorLink: "#contact-us",
    title: "Contact Us",
  },
];

const NavigationBar = () => {
  const isMobileAndTablet = useMedia("(max-width: 991px)");
  const [showMenu, toggleMenu] = React.useState(false);

  React.useEffect(() => {
    if (isMobileAndTablet && showMenu) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.removeProperty("overflow");
    }
  }, [showMenu, isMobileAndTablet]);

  React.useEffect(() => {
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, []);

  return (
    <>
      {isMobileAndTablet && (
        <div
          className={`hamburger hamburger--spin ${showMenu && "is-active"}`}
          onClick={() => toggleMenu(!showMenu)}
        >
          <div className="hamburger-box">
            <div className="hamburger-inner"></div>
          </div>
        </div>
      )}
      <div className={`NavigationBar ${showMenu && "is-active"}`}>
        <div className="NavigationMenu">
          {NavMenus.map((each) => (
            <div
              className="NavigationTitle"
              key={each.anchorLink}
              onClick={() => toggleMenu(!showMenu)}
            >
              <a href={each.anchorLink}>{each.title}</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default NavigationBar;
